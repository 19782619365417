import React, { useState, useEffect, useContext } from "react";
import { Stack, Box, Container, Grid, useMediaQuery, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AdminDisplayBox from "./containers/AdminDisplayBox";
import AdminDisplayBox2 from "./containers/AdminDisplayBox2";
import AdminDisplayBox3 from "./containers/AdminDisplayBox3";
import AdminDisplayBox4 from "./containers/AdminDisplayBox4";
import Passwordcard from "./containers/Passwordcard";

import { DashboardChart } from "./DashboardChart";
import { DashboardPieChart } from "./DashboardPieChart";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import axios from "axios";
import { apidata } from "../data/data";
import { Store } from "../utils/Store";
import { useTheme } from "@mui/material/styles";

export const AdminDashboard = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Store);
  const { id, token, user_district, location, user_roles } = state.userInfo;
  // const [allcomplains, setAllComplains] = useState(0);
  // const [daycomplains, setDayComplains] = useState(0);
  // const [alleccomplains, setAlleccomplains] = useState(0);
  // const [dayeccomplains, setDayeccomplains] = useState(0);
  // const [allpdcomplains, setAllpdcomplains] = useState(0);
  // const [daypdcomplains, dayPdcomplains] = useState(0);
  // const [allrequest, setAllrequest] = useState(0);
  // const [dayrequest, setDayrequest] = useState(0);

  const [allcomplainsadmin, setAllComplainsAdmin] = useState(0); // get admin dashbord 24-08-26
  const [daycomplainsadmin, setDayComplainsAdmin] = useState(0); // get admin dashbord 24-08-26
  const [alleccomplainsadmin, setAlleccomplainsAdmin] = useState(0); // get admin dashbord 24-08-26
  const [dayeccomplainsadmin, setDayeccomplainsAdmin] = useState(0); // get admin dashbord 24-08-26
  const [allpdcomplainsadmin, setAllpdcomplainsAdmin] = useState(0); // get admin dashbord 24-08-26
  const [daypdcomplainsadmin, dayPdcomplainsAdmin] = useState(0); // get admin dashbord 24-08-26
  const [allrequestadmin, setAllrequestAdmin] = useState(0); // get admin dashbord 24-08-26
  const [dayrequestadmin, setDayrequestAdmin] = useState(0); 

// get admin dashbord 24-08-26
  const updateComplainsCount = () => {
    setDayComplainsAdmin(dayeccomplainsadmin + daypdcomplainsadmin);
  };

  useEffect(() => {
  // get admin dashbord 24-08-26
    updateComplainsCount();
    fetchComplains(location);

    // Refresh Window
    // const interval = setInterval(() => {
    //   window.location.reload();
    // }, 3000000); // 5 minutes in milliseconds

    // return () => clearInterval(interval); // Clean up on component unmount

    
  }, [dayeccomplainsadmin, daypdcomplainsadmin]); // get admin dashbord 24-08-26

  const fetchComplains = (location) => {
    axios
      .get(apidata.api + "edritem/admindashboard", {   // get admin dashbord 24-08-26
        params: { district: location, activerole: user_roles[0].id },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status == 200) {
          // setAllComplains(response.data.data.tot_all_com);
          // setDayComplains(response.data.data.tot_today_com);
          // setAlleccomplains(response.data.data.tot_all_ec_com);
          // setDayeccomplains(response.data.data.tot_today_ec_com);
          // setAllpdcomplains(response.data.data.tot_all_pol_com);
          // dayPdcomplains(response.data.data.tot_today_pol_com);
          // setAllrequest(response.data.data.tot_all_req);
          // setDayrequest(response.data.data.tot_today_req);

          // get dashbord count 24-08-26
          setAllComplainsAdmin(response.data.data.tot_all_com_admin);
          // setDayComplainsAdmin(response.data.data.tot_today_com_admin);
          setAlleccomplainsAdmin(response.data.data.tot_all_ec_com_admin);
          setDayeccomplainsAdmin(response.data.data.tot_today_ec_com_admin);
          setAllpdcomplainsAdmin(response.data.data.tot_all_pol_com_admin);
          dayPdcomplainsAdmin(response.data.data.tot_today_pol_com_admin);
          setAllrequestAdmin(response.data.data.tot_all_req_admin);
          setDayrequestAdmin(response.data.data.tot_today_req_admin);

          console.log(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container
      component="main"
      style={{ background: "#efefef", minHeight: "100vh" }}
    >
      <NotificationContainer />

      <Grid container >

       {/* election name */}
       <Grid item sm={12} style={{ textAlign: 'center', marginTop: '10px'}}>
        <Typography color="primary" variant="h5">Parliamentary Election - 2024</Typography>
      </Grid>

      <Grid item sm={12}>
        <Box
          sx={{
            marginTop: 3,
            marginBottom: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Passwordcard />
        </Box>
      </Grid>
      <Grid item sm={12}>
        <Box
          sx={{
            marginTop: 2,
            marginBottom: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end"
          }}
        >
          {/* // get admin dashbord 24-08-26 */}
          <Stack
            spacing={3}
            // direction="row"
            direction={isMobile ? "column" : "row"}
            sx={{
              width: "100%",
            }}
          >
            <AdminDisplayBox
              all1={allcomplainsadmin}
              day1={daycomplainsadmin}
              sx={{
                flex: 1,
              }}
            />
            <AdminDisplayBox2
              all2={alleccomplainsadmin}
              day2={dayeccomplainsadmin}
              sx={{
                flex: 1,
              }}
            />
            <AdminDisplayBox3
              all3={allpdcomplainsadmin}
              day3={daypdcomplainsadmin}
              sx={{
                flex: 1,
              }}
            />
            <AdminDisplayBox4
              all={allrequestadmin}
              day={dayrequestadmin}
              sx={{
                flex: 1,
              }}
            />
          </Stack>
        </Box>
      </Grid>

      <Grid item sm={12}>
      <DashboardChart />
     </Grid>

     {/* Add piechart for closed complain view */}
        
     <Grid item sm={12}>
         <Box
            sx={{
              // marginTop: {xs: 10,  lg: -20},
              marginBottom: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
          <DashboardPieChart />
          </Box>
        </Grid>

      </Grid>
    </Container>
  );
};