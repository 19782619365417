import React, { useState, useEffect, useContext } from "react";
import {
  List,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Pagination,
  Divider,
  Paper,
  Button,
  Container,
  Grid,
  TextField,
} from "@mui/material";
import Itemdetailspoliceall from "./details/Itemdetailspoliceall";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import VerifiedIcon from "@mui/icons-material/Verified";
import { purple } from "@mui/material/colors";
import { Search } from "@mui/icons-material";
import { NotificationContainer } from "react-notifications";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { apidata } from "../data/data";
import { Store } from "../utils/Store";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";

export const AdminComplainAllPage = ({ title, icon }) => {
  const { state, dispatch } = useContext(Store);
  const { id, token, user_district, location, user_roles } = state.userInfo;
  const [liststate, setListstate] = useState(true);
  const [page, setPage] = useState(0);
  const [totalpages, setTotalpages] = useState(0);
  const [loadingcomplains, setLoadingcomplains] = useState(false);
  const [center, setCenter] = useState(location);
  const [val, setVal] = useState(0);
  const [searchkey, setSearchkey] = useState("");
  const [itemstatus, setItemstatus] = useState(
    user_roles[0].id == 9
      ? "POLICE_ASSIGN"
      : user_roles[0].id == 11
      ? "COURT_ASSIGN"
      : "NEW"
  );
  const [count, setCount] = useState(0);
  const { t } = useTranslation();
  const [issearch, setIssearch] = useState(false);
  const [itemdetails, setItemdetails] = useState(null);
  const [complains, setComplains] = useState([]);
  const [size, setSize] = useState(50);
  const [child, setChild] = useState({
    search_key: "",
  });

  useEffect(() => {
    fetchComplains(page, size, center, searchkey, itemstatus,
      //  user_district
      );
  }, [val]);

  const searchSchema = yup.object({
    search_key: yup.string("").required(t("search_key")),
  });

  const fetchComplains = (
    page,
    size,
    center,
    searchkey,
    status,
    user_district
  ) => {
    setLoadingcomplains(true);
    axios
      .get(apidata.api + "edritem/allcomplainadmin", {
        params: {
          init_res_center: center,
          item_type: "COMPLAIN",
          page: page,
          size: size,
          searchkey: searchkey,
          status: status,
          activerole: user_roles[0].id,
          // district: user_district,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status == 200) {
          setLoadingcomplains(false);
          setComplains(response.data.rows);
          setPage(response.data.currentPage);
          // console.log("VVVVVVVVVVVVVVVV", response.data);
          setCount(response.data.count);
          setTotalpages(response.data.totalPages);
        }
      })
      .catch((err) => {
        setLoadingcomplains(false);
        console.log(err);
        //NotificationManager.error(t(err.response.data.message) + ', Please Login', '');
      });
  };

  const onSubmit = (data) => {
    setPage(0);
    setTotalpages(0);
    setComplains([]);
    setSearchkey(data.search_key);
    fetchComplains(page, size, center, data.search_key, user_district);
  };

  const allClick = () => {
    setIssearch(false);
    setChild({
      search_key: "",
    });

    fetchComplains(0, size, center, "", itemstatus, user_district);
  };

  useEffect(() => {
    fetchComplains(page, size, center, searchkey, itemstatus, user_district);
  }, [val]);

  const handlePageChange = (event, value) => {
    const val = value - 1;
    if (issearch) {
      fetchComplains(val, size, center, searchkey, itemstatus, user_district);
    } else {
      fetchComplains(val, size, center, searchkey, itemstatus, user_district);
      setPage(val);
    }
  };

  const itemClick = (item) => {
    setListstate(false);
    setItemdetails(item);
  };

  const changeFormstate = () => {
    setListstate(!liststate);
    //setVal(val + 1);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setIssearch(true);
    setChild((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(searchSchema),
  });

  return (
    <Container component="main" minWidth="xs">
      <NotificationContainer />
      {liststate && (
        <div>
          <NotificationContainer />
          <Paper
            elevation={5}
            style={{
              marginTop: 20,
              paddingTop: "10px",
              paddingLeft: "10px",
              paddingBottom: "10px",
              background: "#fafafa",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container style={{ borderColor: "#fff", padding: 5 }}>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    sx={{
                      marginLeft: 1,
                      marginRight: 10,
                      marginTop: 1,
                      marginBottom: 0,
                      "& .MuiInputBase-root": {
                        height: 40,
                      },
                    }}
                    label={t("search_key")}
                    id="search_key"
                    name="search_key"
                    {...register("search_key", { required: true })}
                    onChange={handleInputChange}
                    value={child.search_key}
                  />
                </Grid>
                {errors.search_key && <p>{errors.search_key?.message}</p>}
                <Grid item sm={2}>
                  <Button
                    style={{ marginLeft: 20, marginBottom: 20, marginTop: 10 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    <Search style={{ marginRight: 10 }} />
                    {t("search")}
                  </Button>
                </Grid>
                {issearch && (
                  <Grid item sm={2}>
                    <Button
                      style={{ marginBottom: 20, marginTop: 10 }}
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={allClick}
                    >
                      {t("get all")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
          </Paper>
          <Paper elevation={5} style={{ padding: "30px", minHeight: 400 }}>
            <Grid container>
              <Grid item sm={12}>
                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                  {!complains.length && !loadingcomplains && (
                    <p>No record found</p>
                  )}
                  {loadingcomplains && <p>Loading ...</p>}
                  {!loadingcomplains &&
                    complains.map((c) => {
                      let col = c.org_type == "PD" ? "#009900" : purple[500];
                      return (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            onClick={() => {
                              itemClick(c);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                sx={{
                                  backgroundColor: col,
                                }}
                              >
                                {c.status === "VERIFIED" ? (
                                  <VerifiedIcon />
                                ) : (
                                  <EmojiPeopleIcon />
                                )}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={c.title}
                              secondary={
                                (c.item_ref !== null ? c.item_ref : "") +
                                " Reference: EDRLAE2025" +
                                c.id +
                                " " +
                                format(
                                  new Date(c.createdAt),
                                  "MM/dd/yyyy HH:mm:ss"
                                )
                              }
                            />
                          </ListItem>
                          <Divider />
                        </>
                      );
                    })}
                </List>
              </Grid>
            </Grid>
            <Grid item sm={2}></Grid>
            <Grid item sm={8} style={{ marginTop: 10 }}>
              <Pagination
                style={{ marginTop: 30 }}
                count={totalpages}
                color="secondary"
                page={page + 1}
                onChange={handlePageChange}
              />
            </Grid>
            <Grid item sm={2}></Grid>
          </Paper>
        </div>
      )}
      {!liststate && (
        <Itemdetailspoliceall
          item={itemdetails}
          changeState={changeFormstate}
        />
      )}
    </Container>
  );
};