import React, { useContext, useState } from "react";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { useTranslation } from "react-i18next";
import { apidata } from "../../data/data";
import axios from "axios";
import { Store } from "../../utils/Store";
import { useTheme } from "@emotion/react";

function ClosedCountChartDistrict() {
  const { t } = useTranslation();
  const { state } = useContext(Store);
  const { token, location, user_roles } = state.userInfo;

  const [edrcomplainsnew, setEdrcomplainsnew] = useState(0);
  const [edrcomplainsopen, setEdrcomplainsopen] = useState(0);
  const [edrcomplainsclose, setEdrcomplainsclose] = useState(0);
  const [edrcomplainspoliceassign, setEdrcomplainspoliceassign] = useState(0);
  const [edrcomplainspoliceopen, setEdrcomplainspoliceopen] = useState(0);
  const [edrcomplainspoliceclose, setEdrcomplainspoliceclose] = useState(0);

  const data2 = [
    { name: "NEW", value: edrcomplainsnew },
    { name: "OPEN", value: edrcomplainsopen },
    { name: "CLOSE", value: edrcomplainsclose },
    { name: "POLICE ASSIGN", value: edrcomplainspoliceassign },
    { name: "POLICE OPEN", value: edrcomplainspoliceopen },
    { name: "POLICE CLOSE", value: edrcomplainspoliceclose },
  ];

  const COLORSEDR = [
    "#b091db",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#f58caf",
  ];

  useEffect(() => {
    fetchCountSummary();
  }, []);

  const fetchCountSummary = () => {
    axios
      .get(apidata.api + "edritem/countsummaryfordistrict", {
        params: { district: location, activerole: user_roles[0].id },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status === 200) {
          setEdrcomplainsnew(response.data.data.edr_complain_new);
          setEdrcomplainsopen(response.data.data.edr_complain_open);
          setEdrcomplainsclose(response.data.data.edr_complain_close);
          setEdrcomplainspoliceassign(
            response.data.data.edr_complain_police_assign
          );
          setEdrcomplainspoliceopen(
            response.data.data.edr_complain_police_open
          );
          setEdrcomplainspoliceclose(
            response.data.data.edr_complain_police_close
          );
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const chartHeight = isMobile ? 150 : 200;
  const chartWidth = isMobile ? "100%" : "25%";

  return (
    <>
      <Stack
        spacing={3}
        direction={isMobile ? "column" : "row"}
        sx={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {/* EDR Complain Chart */}
        <Card sx={{ width: chartWidth }}>
          <CardContent>
            <Typography
              style={{
                fontFamily: "Arial",
                textAlign: "center",
                color: "#8236c9",
                fontSize: "20px",
                marginBottom: "10px",
              }}
            >
              {t("EDR Complain")}
            </Typography>
            <ResponsiveContainer width="100%" height={chartHeight}>
              <PieChart>
                <Pie
                  data={data2}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={isMobile ? 30 : 60}
                  fill="#8884d8"
                  label
                >
                  {data2.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORSEDR[index % COLORSEDR.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}

export default ClosedCountChartDistrict;