import React from "react";
import { Container, Grid } from "@mui/material";

//Add piechart for closed complain view
import { NotificationContainer } from "react-notifications";
import ClosedCountChartDistrict from "./containers/ClosedCountChartDistrict";

export const DashboardPieChartDistrict = () => {
  return (
    <Container
      component="main"
      style={{ background: "#efefef", minHeight: "170vh", minWidth: "170vh" }}
    >
      <NotificationContainer />
      <Grid container>
        <Grid item sm={12}>
          <ClosedCountChartDistrict/>
        </Grid>
      </Grid>
    </Container>
  );
};