export const apidata = {
  //api: 'http://192.168.10.89:3034/'
  // api: "http://127.0.0.1:3034/",
  // api: 'https://43.224.126.231/api/'
  // api: 'http://192.168.175.173:3034/'
  api: 'https://ecedr.elections.gov.lk/api/'
};



export const resmode_data = [
  {
    id: 1,
    value_en: "Phone",
    value_si: "Phone",
    value_ta: "Phone",
  },
  {
    id: 2,
    value_en: "Email",
    value_si: "Email",
    value_ta: "Email",
  },
  {
    id: 3,
    value_en: "Letter",
    value_si: "Letter",
    value_ta: "Letter",
  },
  {
    id: 4,
    value_en: "Fax",
    value_si: "Fax",
    value_ta: "Fax",
  },
  {
    id: 5,
    value_en: "SMS",
    value_si: "SMS",
    value_ta: "SMS",
  },
  {
    id: 6,
    value_en: "Tell Commision",
    value_si: "Tell Commision",
    value_ta: "Tell Commision",
  },
  {
    id: 7,
    value_en: "Whatsapp",
    value_si: "Whatsapp",
    value_ta: "Whatsapp",
  },
  {
    id: 8,
    value_en: "Other Social Media",
    value_si: "Other Social Media",
    value_ta: "Other Social Media",
  },
  {
    id: 9,
    value_en: "Direct",
    value_si: "Direct",
    value_ta: "Direct",
  },
  {
    id: 10,
    value_en: "Mobile App",
    value_si: "Mobile App",
    value_ta: "Mobile App",
  },
];

export const district_data = [
  {
    id: 1,
    value_en: "Colombo",
    value_si: "කොළඹ",
    value_ta: "கொழும்பு",
  },
  {
    id: 2,
    value_en: "Gampaha",
    value_si: "ගම්පහ",
    value_ta: "கம்பஹா",
  },
  {
    id: 3,
    value_en: "Kalutara",
    value_si: "කළුතර",
    value_ta: "களுத்துறை",
  },
  {
    id: 4,
    value_en: "Mahanuwara		",
    value_si: "මහනුවර",
    value_ta: "மஹநுவர",
  },
  {
    id: 5,
    value_en: "Matale",
    value_si: "මාතලේ",
    value_ta: "மாத்தளை",
  },
  {
    id: 6,
    value_en: "Nuwaraeliya",
    value_si: "නුවරඑළිය",
    value_ta: "நுவரெலிய",
  },
  {
    id: 7,
    value_en: "Galle",
    value_si: "ගාල්ල",
    value_ta: "காலி",
  },
  {
    id: 8,
    value_en: "Matara",
    value_si: "මාතර",
    value_ta: "மாத்தறை",
  },
  {
    id: 9,
    value_en: "Hambantota",
    value_si: "හම්බන්තොට",
    value_ta: "அம்பாந்தோட்டை",
  },
  {
    id: 10,
    value_en: "Jaffna",
    value_si: "යාපනය",
    value_ta: "யாழ்ப்பாணம்",
  },
  {
    id: 11,
    value_en: "Kilinochchi",
    value_si: "කිලිනොචිචිය",
    value_ta: "கிளிநொச்சி",
  },
  {
    id: 12,
    value_en: "Mannar",
    value_si: "මන්නාරම",
    value_ta: "மன்னார்",
  },
  {
    id: 13,
    value_en: "Vavuniya",
    value_si: "වව්නියාව",
    value_ta: "வவுனியா",
  },
  {
    id: 14,
    value_en: "Mullativu",
    value_si: "මුලතිව්",
    value_ta: "முல்லைத்தீவு",
  },
  {
    id: 15,
    value_en: "Batticaloa",
    value_si: "මඩකලපුව",
    value_ta: "மட்டக்களப்பு",
  },
  {
    id: 16,
    value_en: "Ampara",
    value_si: "අම්පාර",
    value_ta: "அம்பாறை",
  },
  {
    id: 17,
    value_en: "Trincomalee",
    value_si: "ත්‍රිකුණාමලය",
    value_ta: "திருகோணமலை",
  },
  {
    id: 18,
    value_en: "Kurunegala",
    value_si: "කුරුණෑගල",
    value_ta: "குருநாகல்",
  },
  {
    id: 19,
    value_en: "Puttalam",
    value_si: "පුත්තලම",
    value_ta: "புத்தளம்",
  },
  {
    id: 20,
    value_en: "Anuradhapura",
    value_si: "අනුරාධපුර",
    value_ta: "அனுராதபுரம்",
  },
  {
    id: 21,
    value_en: "Polonnaruwa",
    value_si: "පොළොන්නරුව",
    value_ta: "பொலன்னறுவை",
  },
  {
    id: 22,
    value_en: "Badulla",
    value_si: "බදුල්ල",
    value_ta: "பதுளை",
  },
  {
    id: 23,
    value_en: "Moneragala",
    value_si: "මොණරාගල",
    value_ta: "மொணராகலை",
  },
  {
    id: 24,
    value_en: "Ratnapura",
    value_si: "රත්නපුර",
    value_ta: "இரத்தினபுரி",
  },
  {
    id: 25,
    value_en: "Kegalle",
    value_si: "කෑගල්ල",
    value_ta: "கேகாலை",
  },
];

export const location_data = [
  {
    id: 1,
    value_en: "EDR Colombo",
    value_si: "කොළඹ",
    value_ta: "கொழும்பு",
  },
  {
    id: 2,
    value_en: "EDR Gampaha",
    value_si: "ගම්පහ",
    value_ta: "கம்பஹா",
  },
  {
    id: 3,
    value_en: "EDR Kalutara",
    value_si: "කළුතර",
    value_ta: "களுத்துறை",
  },
  {
    id: 4,
    value_en: "EDR Mahanuwara		",
    value_si: "මහනුවර",
    value_ta: "மஹநுவர",
  },
  {
    id: 5,
    value_en: "EDR Matale",
    value_si: "මාතලේ",
    value_ta: "மாத்தளை",
  },
  {
    id: 6,
    value_en: "EDR Nuwaraeliya",
    value_si: "නුවරඑළිය",
    value_ta: "நுவரெலிய",
  },
  {
    id: 7,
    value_en: "EDR Galle",
    value_si: "ගාල්ල",
    value_ta: "காலி",
  },
  {
    id: 8,
    value_en: "EDR Matara",
    value_si: "මාතර",
    value_ta: "மாத்தறை",
  },
  {
    id: 9,
    value_en: "EDR Hambantota",
    value_si: "හම්බන්තොට",
    value_ta: "அம்பாந்தோட்டை",
  },
  {
    id: 10,
    value_en: "EDR Jaffna",
    value_si: "යාපනය",
    value_ta: "யாழ்ப்பாணம்",
  },
  {
    id: 11,
    value_en: "EDR Kilinochchi",
    value_si: "කිලිනොචිචිය",
    value_ta: "கிளிநொச்சி",
  },
  {
    id: 12,
    value_en: "EDR Mannar",
    value_si: "මන්නාරම",
    value_ta: "மன்னார்",
  },
  {
    id: 13,
    value_en: "EDR Vavuniya",
    value_si: "වව්නියාව",
    value_ta: "வவுனியா",
  },
  {
    id: 14,
    value_en: "EDR Mullativu",
    value_si: "මුලතිව්",
    value_ta: "முல்லைத்தீவு",
  },
  {
    id: 15,
    value_en: "EDR Batticaloa",
    value_si: "මඩකලපුව",
    value_ta: "மட்டக்களப்பு",
  },
  {
    id: 16,
    value_en: "EDR Ampara",
    value_si: "අම්පාර",
    value_ta: "அம்பாறை",
  },
  {
    id: 17,
    value_en: "EDR Trincomalee",
    value_si: "ත්‍රිකුණාමලය",
    value_ta: "திருகோணமலை",
  },
  {
    id: 18,
    value_en: "EDR Kurunegala",
    value_si: "කුරුණෑගල",
    value_ta: "குருநாகல்",
  },
  {
    id: 19,
    value_en: "EDR Puttalam",
    value_si: "පුත්තලම",
    value_ta: "புத்தளம்",
  },
  {
    id: 20,
    value_en: "EDR Anuradhapura",
    value_si: "අනුරාධපුර",
    value_ta: "அனுராதபுரம்",
  },
  {
    id: 21,
    value_en: "EDR Polonnaruwa",
    value_si: "පොළොන්නරුව",
    value_ta: "பொலன்னறுவை",
  },
  {
    id: 22,
    value_en: "EDR Badulla",
    value_si: "බදුල්ල",
    value_ta: "பதுளை",
  },
  {
    id: 23,
    value_en: "EDR Moneragala",
    value_si: "මොණරාගල",
    value_ta: "மொணராகலை",
  },
  {
    id: 24,
    value_en: "EDR Ratnapura",
    value_si: "රත්නපුර",
    value_ta: "இரத்தினபுரி",
  },
  {
    id: 25,
    value_en: "EDR Kegalle",
    value_si: "කෑගල්ල",
    value_ta: "கேகாலை",
  },
  {
    id: 26,
    value_en: "EDR Head Office",
    value_si: "EDR Head Office",
    value_ta: "EDR Head Office",
  },
];

export const severity_data = [
  {
    id: 1,
    value_en: "High",
    value_si: "High",
    value_ta: "High",
  },
  {
    id: 2,
    value_en: "Medium",
    value_si: "Medium",
    value_ta: "Medium",
  },
  {
    id: 3,
    value_en: "Low",
    value_si: "Low",
    value_ta: "Low",
  },
];

export const assest_data = [
  {
    id: 1,
    value_en: "Building",
    value_si: "Building",
    value_ta: "Building",
  },
  {
    id: 2,
    value_en: "Vehicle",
    value_si: "Vehicle",
    value_ta: "Vehicle",
  },
  {
    id: 3,
    value_en: "Other",
    value_si: "Other",
    value_ta: "Other",
  },
];

export const sector_data = [
  {
    id: 1,
    value_en: "Government",
    value_si: "Government",
    value_ta: "Government",
  },
  {
    id: 2,
    value_en: "Private",
    value_si: "Private",
    value_ta: "Private",
  },
];

export const typeofproperty_data = [
  {
    id: 1,
    value_en: "Vehicle",
    value_si: "Vehicle",
    value_ta: "Vehicle",
  },
  {
    id: 2,
    value_en: "MC",
    value_si: "MC",
    value_ta: "MC",
  },
  {
    id: 3,
    value_en: "Pedal Cycle",
    value_si: "Pedal Cycle",
    value_ta: "Pedal Cycle",
  },
  {
    id: 4,
    value_en: "Posters",
    value_si: "Posters",
    value_ta: "Posters",
  },
  {
    id: 5,
    value_en: "Cutouts",
    value_si: "Cutouts",
    value_ta: "Cutouts",
  },
  {
    id: 6,
    value_en: "Banners",
    value_si: "Banners",
    value_ta: "Banners",
  },
  {
    id: 7,
    value_en: "Other",
    value_si: "Other",
    value_ta: "Other",
  },
];

export const vehi_category_data = [
  {
    id: 1,
    value_en: "Car",
    value_si: "Car",
    value_ta: "Car",
  },
  {
    id: 2,
    value_en: "Van",
    value_si: "Van",
    value_ta: "Van",
  },
  {
    id: 3,
    value_en: "Jeep",
    value_si: "Jeep",
    value_ta: "Jeep",
  },
  {
    id: 4,
    value_en: "Bus",
    value_si: "Bus",
    value_ta: "Bus",
  },
  {
    id: 5,
    value_en: "Motar Bycycle",
    value_si: "Motar Bycycle",
    value_ta: "Motar Bycycle",
  },
  {
    id: 6,
    value_en: "Other",
    value_si: "Other",
    value_ta: "Other",
  },
];

export const report_type_data = [
  {
    id: 1,
    value_en: "District Summary Report",
    value_si: "දිස්ත්‍රික් පැමිණිලි සාරාංශ වාර්තාව",
    value_ta: "Summery Report",
  },
  {
    id: 2,
    value_en: "Category Via Summary Report",
    value_si: "පැමිණිලි වර්ගය අනුව සාරාංශ වාර්තාව",
    value_ta: "Category Via Summery Report",
  },
  {
    id: 3,
    value_en: "Detail Report",
    value_si: "පැමිණිලි සවිස්තර වාර්තාව",
    value_ta: "Detail Report",
  },
  {
    id: 4,
    value_en: "Total Complain Summary Report",
    value_si: "සම්පූර්ණ පැමිණිලි සාරාංශ වාර්තාව",
    value_ta: "Total Complain Summary Report",
  },
];

export const report_level_data = [
  {
    id: 27,
    value_en: "National Level",
    value_si: "National Level",
    value_ta: "National Level",
    loc_id: 27,
  },
  {
    id: 26,
    value_en: "Head Quarters",
    value_si: "Head Quarters",
    value_ta: "Head Quarters",
    loc_id: 26,
  },
  {
    id: 1,
    value_en: "Colombo",
    value_si: "කොළඹ",
    value_ta: "கொழும்பு",
    loc_id: 1,
  },
  {
    id: 2,
    value_en: "Gampaha",
    value_si: "ගම්පහ",
    value_ta: "கம்பஹா",
    loc_id: 2,
  },
  {
    id: 3,
    value_en: "Kalutara",
    value_si: "කළුතර",
    value_ta: "களுத்துறை",
    loc_id: 3,
  },
  {
    id: 4,
    value_en: "Mahanuwara		",
    value_si: "මහනුවර",
    value_ta: "மஹநுவர",
    loc_id: 4,
  },
  {
    id: 5,
    value_en: "Matale",
    value_si: "මාතලේ",
    value_ta: "மாத்தளை",
    loc_id: 5,
  },
  {
    id: 6,
    value_en: "Nuwaraeliya",
    value_si: "නුවරඑළිය",
    value_ta: "நுவரெலிய",
    loc_id: 6,
  },
  {
    id: 7,
    value_en: "Galle",
    value_si: "ගාල්ල",
    value_ta: "காலி",
    loc_id: 7,
  },
  {
    id: 8,
    value_en: "Matara",
    value_si: "මාතර",
    value_ta: "மாத்தறை",
    loc_id: 8,
  },
  {
    id: 9,
    value_en: "Hambantota",
    value_si: "හම්බන්තොට",
    value_ta: "அம்பாந்தோட்டை",
    loc_id: 9,
  },
  {
    id: 10,
    value_en: "Jaffna",
    value_si: "යාපනය",
    value_ta: "யாழ்ப்பாணம்",
    loc_id: 10,
  },
  {
    id: 11,
    value_en: "Kilinochchi",
    value_si: "කිලිනොචිචිය",
    value_ta: "கிளிநொச்சி",
    loc_id: 11,
  },
  {
    id: 12,
    value_en: "Mannar",
    value_si: "මන්නාරම",
    value_ta: "மன்னார்",
    loc_id: 12,
  },
  {
    id: 13,
    value_en: "Vavuniya",
    value_si: "වව්නියාව",
    value_ta: "வவுனியா",
    loc_id: 13,
  },
  {
    id: 14,
    value_en: "Mullativu",
    value_si: "මුලතිව්",
    value_ta: "முல்லைத்தீவு",
    loc_id: 14,
  },
  {
    id: 15,
    value_en: "Batticaloa",
    value_si: "මඩකලපුව",
    value_ta: "மட்டக்களப்பு",
    loc_id: 15,
  },
  {
    id: 16,
    value_en: "Ampara",
    value_si: "අම්පාර",
    value_ta: "அம்பாறை",
    loc_id: 16,
  },
  {
    id: 17,
    value_en: "Trincomalee",
    value_si: "ත්‍රිකුණාමලය",
    value_ta: "திருகோணமலை",
    loc_id: 17,
  },
  {
    id: 18,
    value_en: "Kurunegala",
    value_si: "කුරුණෑගල",
    value_ta: "குருநாகல்",
    loc_id: 18,
  },
  {
    id: 19,
    value_en: "Puttalam",
    value_si: "පුත්තලම",
    value_ta: "புத்தளம்",
    loc_id: 19,
  },
  {
    id: 20,
    value_en: "Anuradhapura",
    value_si: "අනුරාධපුර",
    value_ta: "அனுராதபுரம்",
    loc_id: 20,
  },
  {
    id: 21,
    value_en: "Polonnaruwa",
    value_si: "පොළොන්නරුව",
    value_ta: "பொலன்னறுவை",
    loc_id: 21,
  },
  {
    id: 22,
    value_en: "Badulla",
    value_si: "බදුල්ල",
    value_ta: "பதுளை",
    loc_id: 22,
  },
  {
    id: 23,
    value_en: "Moneragala",
    value_si: "මොණරාගල",
    value_ta: "மொணராகலை",
    loc_id: 23,
  },
  {
    id: 24,
    value_en: "Ratnapura",
    value_si: "රත්නපුර",
    value_ta: "இரத்தினபுரி",
    loc_id: 24,
  },
  {
    id: 25,
    value_en: "Kegalle",
    value_si: "කෑගල්ල",
    value_ta: "கேகாலை",
    loc_id: 25,
  },
];

export const assignee_data = [
  {
    id: 1,
    value_en: "Police Department",
    value_si: "Police Department",
    value_ta: "Police Department",
    item_level: "POLICE_ASSIGN",
  },
  {
    id: 2,
    value_en: "District Office",
    value_si: "District Office",
    value_ta: "District Office",
    item_level: "NEW",
  },
  
  // {
  //   id: 3,
  //   value_en: "Leagal Officer",
  //   value_si: "Leagal Officer",
  //   value_ta: "Leagal Officer",
  //   item_level: "COURT_ASSIGN",
  // },
];

export const category_data = [
  {
    id: 1,
    value_en: "Verbal intimidation",
    value_si: "Verbal intimidation",
    value_ta: "Verbal intimidation",
  },
  {
    id: 2,
    value_en: "Threaten with weapons/ Attempted murder by shooting",
    value_si: "Threaten with weapons/ Attempted murder by shooting",
    value_ta: "Threaten with weapons/ Attempted murder by shooting",
  },
  {
    id: 3,
    value_en: "Assault/ Mob attack",
    value_si: "Assault/ Mob attack",
    value_ta: "Assault/ Mob attack",
  },
  {
    id: 4,
    value_en: "Cause serious injuries/ Attempted murder",
    value_si: "Cause serious injuries/ Attempted murder",
    value_ta: "Cause serious injuries/ Attempted murder",
  },
  {
    id: 5,
    value_en: "Murder",
    value_si: "Murder",
    value_ta: "Murder",
  },
  {
    id: 6,
    value_en: "Abduction",
    value_si: "Abduction",
    value_ta: "Abduction",
  },
  {
    id: 7,
    value_en: "Shooting",
    value_si: "Shooting",
    value_ta: "Shooting",
  },
  {
    id: 8,
    value_en: "Arson",
    value_si: "Arson",
    value_ta: "Arson",
  },
  {
    id: 9,
    value_en: "Riots/ Disrupting meetings",
    value_si: "Riots/ Disrupting meetings",
    value_ta: "Riots/ Disrupting meetings",
  },
  {
    id: 10,
    value_en: "Damage to property/ Property theft",
    value_si: "Damage to property/ Property theft",
    value_ta: "Damage to property/ Property theft",
  },
  {
    id: 11,
    value_en: "Damage to property/ Property theft",
    value_si: "Damage to property/ Property theft",
    value_ta: "Damage to property/ Property theft",
  },
  {
    id: 12,
    value_en: "Misuse of public property",
    value_si: "Misuse of public property",
    value_ta: "Misuse of public property",
  },
  {
    id: 13,
    value_en: "Involvement of public officers in politics",
    value_si: "Involvement of public officers in politics",
    value_ta: "Involvement of public officers in politics",
  },
  {
    id: 14,
    value_en:
      "Appointment/ promotion/ transfer of officers/ Extension of service period/Conducting examination",
    value_si:
      "Appointment/ promotion/ transfer of officers/ Extension of service period/Conducting examinations",
    value_ta:
      "Appointment/ promotion/ transfer of officers/ Extension of service period/Conducting examinations",
  },
  {
    id: 15,
    value_en: "Misuse of the electronic media",
    value_si: "Misuse of the electronic media",
    value_ta: "Misuse of the electronic media",
  },
  {
    id: 16,
    value_en:
      "False information/ Forgery/ Hate Speech/ Insulting/ Racist remarks",
    value_si:
      "False information/ Forgery/ Hate Speech/ Insulting/ Racist remarks",
    value_ta:
      "False information/ Forgery/ Hate Speech/ Insulting/ Racist remarks",
  },
  {
    id: 17,
    value_en: "Obstructing propaganda/ campaign",
    value_si: "Obstructing propaganda/ campaign",
    value_ta: "Obstructing propaganda/ campaign",
  },
  {
    id: 18,
    value_en:
      "Illegal posters/ banners/ cutouts on display/ Distribution of leaflets",
    value_si:
      "Illegal posters/ banners/ cutouts on display/ Distribution of leaflets",
    value_ta:
      "Illegal posters/ banners/ cutouts on display/ Distribution of leaflets",
  },
  {
    id: 19,
    value_en:
      "Distribution of goods/ treatment of goods/ Distribution of money/ Distribution of Land Deeds/ Openings",
    value_si:
      "Distribution of goods/ treatment of goods/ Distribution of money/ Distribution of Land Deeds/ Openings",
    value_ta:
      "Distribution of goods/ treatment of goods/ Distribution of money/ Distribution of Land Deeds/ Openings",
  },
  {
    id: 20,
    value_en: "Conducting of rallies/ processions/ illegal political offices",
    value_si: "Conducting of rallies/ processions/ illegal political offices",
    value_ta: "Conducting of rallies/ processions/ illegal political offices",
  },
  {
    id: 21,
    value_en: "By religious leaders and place(s) involving propaganda",
    value_si: "By religious leaders and place(s) involving propaganda",
    value_ta: "By religious leaders and place(s) involving propaganda",
  },
  {
    id: 22,
    value_en: "Complaints related to postal votes",
    value_si: "Complaints related to postal votes",
    value_ta: "Complaints related to postal votes",
  },
  {
    id: 23,
    value_en:
      "Complaint related to polling booth/ Election committee/ Electoral materials",
    value_si:
      "Complaint related to polling booth/ Election committee/ Electoral materials",
    value_ta:
      "Complaint related to polling booth/ Election committee/ Electoral materials",
  },
  {
    id: 24,
    value_en: "Obstructing in casting voting",
    value_si: "Obstructing in casting voting",
    value_ta: "Obstructing in casting voting",
  },
  {
    id: 25,
    value_en: "Organizing illegal events",
    value_si: "Organizing illegal events",
    value_ta: "Organizing illegal events",
  },
  {
    id: 26,
    value_en: "Treats for voters",
    value_si: "Treats for voters",
    value_ta: "Treats for voters",
  },
  {
    id: 27,
    value_en: "Damage to Election Offices/ Posters/ Banners etc",
    value_si: "Damage to Election Offices/ Posters/ Banners etc",
    value_ta: "Damage to Election Offices/ Posters/ Banners etc",
  },
  {
    id: 28,
    value_en: "Social media advertising",
    value_si: "Social media advertising",
    value_ta: "Social media advertising",
  },
  {
    id: 29,
    value_en: "Setting fire to ballot boxes",
    value_si: "Setting fire to ballot boxes",
    value_ta: "Setting fire to ballot boxes",
  },
  {
    id: 30,
    value_en: "Chasing out of poling Agents/ Staff",
    value_si: "Chasing out of poling Agents/ Staff",
    value_ta: "Chasing out of poling Agents/ Staff",
  },
  {
    id: 31,
    value_en: "Chasing out of Voters",
    value_si: "Chasing out of Voters",
    value_ta: "Chasing out of Voters",
  },
  {
    id: 32,
    value_en: "Others",
    value_si: "Others",
    value_ta: "Others",
  },
];
