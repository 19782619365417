// import React, { useState, useEffect, useContext } from 'react'
// import {Stack, Box, Container, Grid, Typography } from "@mui/material";
// import { useTranslation } from "react-i18next";
// import ComplainDisplayBox from './containers/ComplainDisplayBox';
// import InquaryDisplayBox from './containers/InquaryDisplayBox';
// import Passwordcard from './containers/Passwordcard';
// import { NotificationContainer, NotificationManager } from 'react-notifications';
// import axios from 'axios';
// import { apidata, } from '../data/data';
// import { Store } from '../utils/Store';

// export const Dashboard = () => {
//   const { t } = useTranslation();
//   const { state, dispatch } = useContext(Store);
//   const { id, token, user_district, location, user_roles } = state.userInfo;
//   const [allcomplains, setAllComplains] = useState(0);
//   const [allpdcomplains, setAllpdcomplains] = useState(0);
//   const [alleccomplains, setAlleccomplains] = useState(0);
//   const [daycomplains, setDayComplains] = useState(0);
//   const [daypdcomplains, dayPdcomplains] = useState(0);
//   const [dayeccomplains, setDayeccomplains] = useState(0);
//   const [allrequest, setAllrequest] = useState(0);
//   const [dayrequest, setDayrequest] = useState(0);

//   useEffect(() => {
//     fetchComplains(location);
//   }, []);

//   const fetchComplains = (location) => {
//     axios.get(apidata.api + 'edritem/dashboard', { params: { district: location, activerole: user_roles[0].id }, headers: { "Authorization": `Bearer ${token}` } })
//       .then(response => {
//         if (response.status == 200) {
//           setAllComplains(response.data.data.tot_all_com);
//           setDayComplains(response.data.data.tot_today_com);
//           setAllrequest(response.data.data.tot_all_req);
//           setDayrequest(response.data.data.tot_today_req);
//           setAllpdcomplains(response.data.data.tot_all_pol_com);
//           setAlleccomplains(response.data.data.tot_all_ec_com);
//           dayPdcomplains(response.data.data.tot_today_pol_com);
//           setDayeccomplains(response.data.data.tot_today_ec_com);
//           console.log(response.data.data);
//         }
//       })
//       .catch(err => {
//         console.log(err.message);
//       });
//   }

//   return (
//     <Container component="main" style={{ background: '#efefef', minHeight: '100vh' }} >
//       <NotificationContainer />
//       <Grid container>
//         {/* election name */}
//       <Grid item sm={12} style={{ textAlign: 'center', marginTop: '10px'}}>
//         <Typography color="primary" variant="h5">Parliamentary Election - 2024</Typography>
//       </Grid>
//         <Grid item sm={10}>
//           <Box
//             sx={{
//               marginTop: 3,
//               marginBottom: 2,
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//             }}
//           >
//             <Stack spacing={2}>
//               <ComplainDisplayBox all={allcomplains} allpd={allpdcomplains} allec={alleccomplains} day={daycomplains} daypd={daypdcomplains} dayec={dayeccomplains}/><InquaryDisplayBox all={allrequest} day={dayrequest} />
//             </Stack>
//           </Box>
//         </Grid>
//         <Grid item sm={2}>
//           <Box
//             sx={{
//               marginTop: 3,
//               marginBottom: 2,
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//             }}
//           >
//             <Passwordcard />
//           </Box>
//         </Grid>
//       </Grid>

//     </Container >
//   );
// };




import React, { useState, useEffect, useContext } from "react";
import { Stack, Box, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComplainDisplayBox from "./containers/ComplainDisplayBox";
import InquaryDisplayBox from "./containers/InquaryDisplayBox";
import Passwordcard from "./containers/Passwordcard";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import axios from "axios";
import { apidata } from "../data/data";
import { Store } from "../utils/Store";
import { DashboardPieChartDistrict } from "./DashboardPieChartDistrict";
import { DashboardChartDistrict } from "./DashboardChartDistrict";

export const Dashboard = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Store);
  const { id, token, user_district, location, user_roles } = state.userInfo;
  const [allcomplains, setAllComplains] = useState(0);
  const [allpdcomplains, setAllpdcomplains] = useState(0);
  const [alleccomplains, setAlleccomplains] = useState(0);
  const [daycomplains, setDayComplains] = useState(0);
  const [daypdcomplains, dayPdcomplains] = useState(0);
  const [dayeccomplains, setDayeccomplains] = useState(0);
  const [allrequest, setAllrequest] = useState(0);
  const [dayrequest, setDayrequest] = useState(0);

  console.log("ffffffff",user_roles[0].id)

  const actRole = user_roles[0].id ;

  useEffect(() => {
    fetchComplains(location);
  }, []);

  const fetchComplains = (location) => {
    axios
      .get(apidata.api + "edritem/dashboard", {
        params: { district: location, activerole: user_roles[0].id },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status == 200) {
          setAllComplains(response.data.data.tot_all_com);
          setDayComplains(response.data.data.tot_today_com);
          setAllrequest(response.data.data.tot_all_req);
          setDayrequest(response.data.data.tot_today_req);
          setAllpdcomplains(response.data.data.tot_all_pol_com);
          setAlleccomplains(response.data.data.tot_all_ec_com);
          dayPdcomplains(response.data.data.tot_today_pol_com);
          setDayeccomplains(response.data.data.tot_today_ec_com);
          console.log(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <Container
      component="main"
      style={{ background: "#efefef", minHeight: "100vh" }}
    >
      <NotificationContainer />
      <Grid container>
        {/* election Name */}
        <Grid item sm={12} style={{ textAlign: "center", marginTop: "10px" }}>
          <Typography color="primary" variant="h5">
            Parliamentary Election - 2024
          </Typography>
        </Grid>
        <Grid item sm={10}></Grid>
        <Grid item sm={2}>
          <Box
            sx={{
              // marginTop: 3,
              // marginBottom: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Passwordcard />
          </Box>
        </Grid>

        <Grid item sm={3}>
          <Box
            sx={{
              marginTop: 3,
              marginBottom: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Stack spacing={2}>
              <ComplainDisplayBox
                all={allcomplains}
                allpd={allpdcomplains}
                allec={alleccomplains}
                day={daycomplains}
                daypd={daypdcomplains}
                dayec={dayeccomplains}
              />
              <InquaryDisplayBox all={allrequest} day={dayrequest} />
            </Stack>
          </Box>
        </Grid>

        {actRole !== 12 && actRole !== 9 && (
        <Grid item sm={4}>
          <Box
            sx={{
              marginTop: 3,
              marginRight: 25,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* <DashboardPieChartDistrict /> */}
          </Box>
        </Grid>
        )}

        {actRole !== 12 && actRole !== 9 && (
        <Grid item sm={5}>
          <DashboardChartDistrict />
        </Grid>
         )} 
      </Grid>
    </Container>
  );
};
