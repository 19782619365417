import React, { useState } from "react";
import { Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { Store } from "../../utils/Store";
import { apidata } from "../../data/data";
import axios from "axios";
import { useEffect } from "react";


function ResmodeCountChart() {
  const { state } = useContext(Store);
  const { token, location, user_roles } = state.userInfo;
  const { t } = useTranslation();
  const [resmodeData, setResmodeData] = useState();


  useEffect(() => {
    fetchResmodeSummary();
  }, []);


  const fetchResmodeSummary = () => {
    axios
      .get(apidata.api + "edritem/resmodesummary", {
        params: { district: location, activerole: user_roles[0].id },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status === 200) {
          setResmodeData(response.data.items);
          console.log("Res Mode Summary", response.data.items);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };


  return (
    <>
      <Typography
        style={{
          fontFamily: "Arial",
          color: "#8236c9",
          fontSize: "22px",
          marginTop: "20px",
        }}
      >
        {t("Response Mode Summary View")}
      </Typography>


      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={1400}
          height={400}
          data={resmodeData}
          margin={{ top: 30, right: 50, bottom: 100 }} // Adjust bottom margin
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="res_mode_label"
            angle={-30}
            textAnchor="end"
            dy={10}
            interval="preserveStartEnd"
          />
          <YAxis domain={[0, 400]} />
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          <Bar dataKey="complain" fill="#8884d8" barSize={30} />
          <Bar dataKey="request" fill="#d19c41" barSize={30} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}


export default ResmodeCountChart;


