import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Box,
  Container,
  FormControl,
  Select,
  Button,
  InputLabel,
  TextField,
  Grid,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Store } from "../utils/Store";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import {
  apidata,
  report_level_data,
  report_type_data,
  category_data,
  location_data,
} from "../data/data";
import ArticleIcon from "@mui/icons-material/Article";
import PrintIcon from "@mui/icons-material/Print";
import DifferenceIcon from "@mui/icons-material/Difference";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Spinner from "./items/Spinner";
import jsPDF from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";
import { font } from "./fonts/font";
import LetterHead from "./image/letterhead2.png";
import CategoryImage from "./image/cat1.png";
import ReactToPrint from "react-to-print";
import "./image/printStyle.css";
import { orange } from "@mui/material/colors";

export const ReportLayout = () => {
  const [isShown, setIsShown] = useState(false);
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Store);
  const [categoryies, setCategoryies] = useState(state.categories);
  const navigate = useNavigate();
  const { id, token, user_district, user_roles, location } = state.userInfo;
  const [category, setCategory] = useState("");
  const [categoryset, setCategoryset] = useState(report_type_data);
  const [catset, setCatset] = useState(category_data);
  const [place, setPlace] = useState("");
  const [placeset, setPlaceset] = useState(report_level_data);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState();
  const [filepath, setFilepath] = useState();
  const [child, setChild] = useState({
    start_date: "",
    end_date: "",
    start_time: "12:00:00",
    end_time: "12:00:00",
  });

  const [isOpenBtn, setIsOpenBtn] = useState(false);

  useEffect(() => {
    const val = JSON.parse(atob(token.split(".")[1]));
    const nowval = Date.now();
    console.log(val.exp * 1000 + " - " + nowval);
    if (val.exp * 1000 < nowval) {
      NotificationManager.error(t("Token is expired, Please Login again"), "");
      dispatch({ type: "USER_LOGOUT" });
      Cookies.remove("ec_edr_userinfo");
      navigate("/login", { replace: true });
    }
  }, []);

  const componentRef = useRef();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setChild((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCategoryChange = (event) => {
    const { name, value } = event.target;
    setCategory(value);
  };

  const handleLocationChange = (event) => {
    const { name, value } = event.target;
    setPlace(value);
  };

  function handleReportButton() {
    setIsOpenBtn(true);
  }
  const formSchema = yup.object({});

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    const lng = localStorage.getItem("i18nextLng");
    //await axios.post(apidata.api + 'report/daystream', {
    await axios
      .post(
        apidata.api + "report/day",
        {
          place: place.loc_id,
          category: category.id,
          start_date: data.start_date,
          start_time: data.start_time,
          end_date: data.end_date,
          end_time: data.end_time,
          lang: lng,
          user_id: id,
          location: location,
          user_role: user_roles[0].id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((data) => {
        //setLoading(false);
        setResult(data.data);
        setIsShown(true);
        setLoading(false);
        console.log(data);
      })
      .catch((error) => {
        setLoading(false);
        NotificationManager.error(t("error_data_save - " + error), "");
      });
  };

  // ----------------------Excel generate category 01------------------------------
  const downloadExcel = () => {
    var total = result.itemcount;
    const newData = result.items.map((row) => {
      delete row.tableData;
      return row;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData, { origin: "A4" });

    XLSX.utils.sheet_add_aoa(workSheet, [[["පාර්ලිමේන්තු මැතිවරණය - 2024"]]], {
      origin: "A1",
    });
    XLSX.utils.sheet_add_aoa(
      workSheet,
      [
        [
          [category.value_si],
          [],
          [
            child.start_date +
              " " +
              child.start_time +
              " To " +
              child.end_date +
              " " +
              child.end_time,
          ],
        ],
      ],
      { origin: "A2" }
    );

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "EDR");
    //Buffer
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    // XLSX.utils.sheet_add_aoa(workSheet, [["Created "+new Date().toISOString()]], {origin:-1});
    XLSX.utils.sheet_add_aoa(workSheet, [["Total ", [total]]], { origin: -1 });
    //Download
    if (category.id === 1) {
      XLSX.writeFile(workBook, "Summery_Report.xlsx");
    } else if (category.id === 2) {
      XLSX.writeFile(workBook, "Category Via Summery_Report.xlsx");
    } else if (category.id === 3) {
      XLSX.writeFile(workBook, "Detail_Report.xlsx");
    } else if (category.id === 4) {
      XLSX.writeFile(workBook, "TotalComplainSummary.xlsx");
    } else {
      return;
    }
  };

  return (
    <Container
      component="main"
      style={{ background: "#efefef", minHeight: "100vh" }}
    >
      <NotificationContainer />

      <Box
        sx={{
          margin: 0,
          padding: 2,
          marginTop: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "auto",
          height: "auto",
          background: "white",
          borderRadius: 2,
          boxShadow: 5,
        }}
      >
        <form id="reportform" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12}>
              <FormControl style={{ minWidth: 220, margin: 10 }}>
                <InputLabel id="demo-simple-select-label">
                  {t("report")}
                </InputLabel>
                <Select
                  style={{ fontSize: 14 }}
                  labelId="demo-simple-select-label"
                  defaultValue={category.value_en}
                  name="category"
                  fullWidth
                  id="category"
                  {...register("category", { required: true })}
                  value={category.value_en}
                  onChange={handleCategoryChange}
                >
                  {categoryset
                    .slice(0, location !== 26 ? 3 : categoryset.length)
                    .map((x) => {
                      return (
                        <MenuItem value={x} style={{ fontSize: 12 }}>
                          {x.value_en}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item sm={3} xs={12}>
              <FormControl style={{ minWidth: 220, margin: 10 }}>
                <TextField
                  type="date"
                  fullWidth
                  label={t("from")}
                  id="start_date"
                  name="start_date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("start_date", { required: true })}
                  value={child.start_date}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormControl style={{ minWidth: 220, margin: 10 }}>
                <TextField
                  type="time"
                  fullWidth
                  // disabled
                  label={t("start_time")}
                  id="start_time"
                  name="start_time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("start_time", { required: true })}
                  value={child.start_time}
                  // value={"12:00:00"}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormControl style={{ minWidth: 220, margin: 10 }}>
                <TextField
                  type="date"
                  fullWidth
                  label={t("to")}
                  id="end_date"
                  name="end_date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("end_date", { required: true })}
                  value={child.end_date}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormControl style={{ minWidth: 220, margin: 10 }}>
                <TextField
                  type="time"
                  fullWidth
                  // disabled
                  //  defaultValue={"12:00:00"}
                  label={t("end_time")}
                  id="end_time"
                  name="end_time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("end_time", { required: true })}
                  value={child.end_time}
                  // value={"12:00:00"}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ gridArea: "footer" }}>
            <FormControl style={{ minWidth: 220, margin: 10 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleReportButton}
              >
                <ArticleIcon style={{ marginRight: 10 }} />
                {t("generate_report")}
              </Button>
            </FormControl>

            <FormControl style={{ minWidth: 180, margin: 10, marginLeft: 400 }}>
              {isOpenBtn ? (
                <ReactToPrint
                  trigger={() => (
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      type="button"
                      // onClick={downloadPdf}
                    >
                      {" "}
                      <PrintIcon style={{ marginRight: 10 }} /> Print Report
                    </Button>
                  )}
                  content={() => componentRef.current}
                />
              ) : (
                " "
              )}
            </FormControl>
            <FormControl style={{ minWidth: 180, margin: 10 }}>
              {isOpenBtn ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  type="button"
                  onClick={downloadExcel}
                >
                  <DifferenceIcon style={{ marginRight: 10 }} />
                  {t("Export as Excel")}
                </Button>
              ) : (
                " "
              )}
            </FormControl>
          </Box>
        </form>
      </Box>
      <Box
        sx={{
          margin: 0,
          padding: 2,
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "auto",
          minHeight: "90vh",
          background: "white",
          // borderRadius: 2,
          // boxShadow: 5,
        }}
        ref={componentRef}
        className="page-container"
      >
        {loading && <Spinner message={t("loading_report")} />}
        {!loading && result && category.id === 1 && (
          <>
            <img src={LetterHead} alt="letterhead" />
            <h3 style={{ margin: 0, marginTop: 40, padding: 0 }}>
              <center>Parliamentary Election 2024</center>
            </h3>
            <h4 style={{ margin: 0, padding: 0 }}>
              <center>Daily Election Related Complains Summery Report</center>
            </h4>
            <p
              style={{ margin: 0, marginBottom: 20, padding: 0, fontSize: 12 }}
            >
              <center>
                Duration: From {child.start_date} {child.start_time} To{" "}
                {child.end_date} {child.end_time}{" "}
              </center>
            </p>
            <table border={1} cellspacing="0" padding="2 " margin-top="10px ">
              <thead>
                <tr>
                  <th width="70%">EDR Center</th>
                  <th>Complain Count</th>
                </tr>
              </thead>

              <tbody>
                {result &&
                  result.items.map((x) => {
                    return (
                      <tr>
                        {x.Name_in_English && <td>{x.Name_in_English}</td>}
                        {/* {x.org_type && <td>{x.org_type == 'PD' ? 'SL Police' : 'District EDR'}</td>} */}
                        <td align="center">{x.Count}</td>
                      </tr>
                    );
                  })}
                {/* {
                  location_data.map(y => {
                    let locState = true;
                    result && result.items.map(x => {
                      if (x.id) {
                        if (y.id == x.id) {
                          locState = false;
                        }
                      } else {
                        locState = false
                      }
                    })
                    if (locState) {
                      return <tr>
                        <td>{y.value_en}</td>
                        <td align='right'>0</td>
                      </tr>
                    }
                  })
                } */}
                <tr>
                  <td>
                    <h4>Total Complains</h4>
                  </td>
                  <td align="center">
                    <h4>{result ? result.itemcount : ""}</h4>
                  </td>
                </tr>
              </tbody>

              {/* <tbody> 
             { renderTableRows()}
              </tbody> */}
            </table>
          </>
        )}
        {!loading && result && category.id === 2 && (
          <>
            <img src={LetterHead} alt="letterhead" />
            <h3 style={{ margin: 0, marginTop: 60, padding: 0 }}>
              <center>Parliamentary Election 2024</center>
            </h3>
            <h4 style={{ margin: 0, padding: 0 }}>
              <center>
                Daily Category Via Election Related Complains Report
              </center>
            </h4>
            <p
              style={{ margin: 0, marginBottom: 20, padding: 0, fontSize: 12 }}
            >
              <center>
                Duration: From {child.start_date} {child.start_time} To{" "}
                {child.end_date} {child.end_time}
              </center>
            </p>
            <table border={1} cellspacing="0" padding="3">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Complain Count</th>
                </tr>
              </thead>
              <tbody>
                {result &&
                  result.items.map((x) => {
                    return (
                      <tr>
                        <td>
                          {categoryies
                            .filter((cat) => cat.id === x.category)
                            .map(
                              (filteredcat) =>
                                filteredcat.id + ". " + filteredcat.value_en
                            )}
                        </td>
                        <td align="center">{x.Count}</td>
                      </tr>
                    );
                  })}
                {/* {result &&
                  categoryies.map((x) => {
                    let state = false;
                    result.items
                      .filter((cat) => cat.category !== x.id)
                      .map((filteredcat) => (state = true));
                    if (state) {
                      return (
                        <tr>
                          <td>{x.value_en}</td>
                          <td align="center">0</td>
                        </tr>
                      );
                    }
                  })} */}
                <tr>
                  <td>
                    <h4>Total Complains</h4>
                  </td>
                  <td align="center">
                    <h4>{result ? result.itemcount : ""}</h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {!loading && result && category.id === 3 && (
          <Box
            sx={{
              margin: 0,
              padding: 2,
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "auto",
              minHeight: "90vh",
              background: "white",
              borderRadius: 2,
            }}
            className="detailReport"
          >
            <img src={LetterHead} alt="letterhead" />
            <h3 style={{ margin: 0, marginTop: 0, padding: 0 }}>
              <center>Parliamentary Election 2024</center>
            </h3>
            <h4 style={{ margin: 0, padding: 0 }}>
              <center>Detail Report</center>
            </h4>
            <p
              style={{ margin: 0, marginBottom: 20, padding: 0, fontSize: 12 }}
            >
              <center>
                Duration: From {child.start_date} To {child.end_date}
              </center>
            </p>
            <table
              sx={{ width: "80%", margin: 1 }}
              border={1}
              cellspacing="0"
              padding="1"
              className="detailReport-table"
            >
              <tr>
                <td width="5%">Id</td>
                <td width="5%">District</td>
                <td width="5%">Category</td>
                <td width="5%">Complainer</td>
                <td width="5%">Party/Indi. Group</td>
                <td width="5%">Police Division</td>
                <td width="5%">Location</td>
                <td width="5%">Status</td>
                <td width="30%">Complain Summery</td>
                <td width="30%">Action</td>
              </tr>
              {result &&
                result.items.map((x) => {
                  return (
                    <tr>
                      <td width="5%">{"EDRPARE2024" + x.id}</td>
                      <td width="5%">{x.district}</td>
                      <td width="5%">{x.category}</td>
                      <td width="5%">{x.complainer_name_en}</td>
                      <td width="5%">{x.party_to}</td>
                      <td width="5%">{x.police_div}</td>
                      <td width="5%">
                        {x.location_en +
                          " ( " +
                          x.item_date +
                          " " +
                          x.item_time +
                          " )"}
                      </td>
                      <td width="5%">{x.item_level}</td>
                      <td width="30%">{x.description}</td>
                      <td width="30%">{x.action}</td>
                    </tr>
                  );
                })}
            </table>
          </Box>
        )}

        {/* test for total complain summary */}
        {!loading && result && category.id === 4 && (
          <Box
            sx={{
              margin: 0,
              padding: 2,
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "auto",
              minHeight: "90vh",
              background: "white",
              borderRadius: 2,
            }}
            className="totalComplainReport"
          >
            <img src={LetterHead} alt="letterhead" />
            <h3 style={{ margin: 0, marginTop: 0, padding: 0 }}>
              <center>Parliamentary Election 2024</center>
            </h3>
            <h4 style={{ margin: 0, padding: 0 }}>
              <center>Total Complain Summary Report</center>
            </h4>
            <p
              style={{ margin: 0, marginBottom: 20, padding: 0, fontSize: 12 }}
            >
              <center>
                Duration: From {child.start_date} To {child.end_date}
              </center>
            </p>

            <table
              sx={{ width: "80%", margin: 1 }}
              border={1}
              cellspacing="0"
              padding="1"
              // className="totalreport-table"
            >
              <tr>
                <th rowSpan="2">District</th>
                <th colspan="11" style={{ backgroundColor: "#e57373" }}>
                  Election Related Normal Crimes
                </th>
                <th colspan="21" style={{ backgroundColor: "#42a5f5" }}>
                  Violation of Elections Law
                </th>
                <th rowSpan="2">Total</th>
              </tr>
              <tr>
                <td style={{ backgroundColor: "#e57373" }}>1</td>
                <td style={{ backgroundColor: "#e57373" }}>2</td>
                <td style={{ backgroundColor: "#e57373" }}>3</td>
                <td style={{ backgroundColor: "#e57373" }}>4</td>
                <td style={{ backgroundColor: "#e57373" }}>5</td>
                <td style={{ backgroundColor: "#e57373" }}>6</td>
                <td style={{ backgroundColor: "#e57373" }}>7</td>
                <td style={{ backgroundColor: "#e57373" }}>8</td>
                <td style={{ backgroundColor: "#e57373" }}>9</td>
                <td style={{ backgroundColor: "#e57373" }}>10</td>
                <td style={{ backgroundColor: "#e57373" }}>11</td>
                {/* <td style={{ backgroundColor: "#e57373" }}>32</td> */}
                <td style={{ backgroundColor: "#42a5f5" }}>12</td>
                <td style={{ backgroundColor: "#42a5f5" }}>13</td>
                <td style={{ backgroundColor: "#42a5f5" }}>14</td>
                <td style={{ backgroundColor: "#42a5f5" }}>15</td>
                <td style={{ backgroundColor: "#42a5f5" }}>16</td>
                <td style={{ backgroundColor: "#42a5f5" }}>17</td>
                <td style={{ backgroundColor: "#42a5f5" }}>18</td>
                <td style={{ backgroundColor: "#42a5f5" }}>19</td>
                <td style={{ backgroundColor: "#42a5f5" }}>20</td>
                <td style={{ backgroundColor: "#42a5f5" }}>21</td>
                <td style={{ backgroundColor: "#42a5f5" }}>22</td>
                <td style={{ backgroundColor: "#42a5f5" }}>23</td>
                <td style={{ backgroundColor: "#42a5f5" }}>24</td>
                <td style={{ backgroundColor: "#42a5f5" }}>25</td>
                <td style={{ backgroundColor: "#42a5f5" }}>26</td>
                <td style={{ backgroundColor: "#42a5f5" }}>27</td>
                <td style={{ backgroundColor: "#42a5f5" }}>28</td>
                <td style={{ backgroundColor: "#42a5f5" }}>29</td>
                <td style={{ backgroundColor: "#42a5f5" }}>30</td>
                <td style={{ backgroundColor: "#42a5f5" }}>31</td>
                <td style={{ backgroundColor: "#42a5f5" }}>32</td>
              </tr>
              {result &&
                result.items.map((x) => {
                  // console.log("test", result);
                  return (
                    <tr>
                      <td>{x.DistrictName}</td>
                      <td>{x.C1}</td>
                      <td>{x.C2}</td>
                      <td>{x.C3}</td>
                      <td>{x.C4}</td>
                      <td>{x.C5}</td>
                      <td>{x.C6}</td>
                      <td>{x.C7}</td>
                      <td>{x.C8}</td>
                      <td>{x.C9}</td>
                      <td>{x.C10}</td>
                      <td>{x.C11}</td>
                      {/* <td>{x.C32}</td> */}
                      <td>{x.C12}</td>
                      <td>{x.C13}</td>
                      <td>{x.C14}</td>
                      <td>{x.C15}</td>
                      <td>{x.C16}</td>
                      <td>{x.C17}</td>
                      <td>{x.C18}</td>
                      <td>{x.C19}</td>
                      <td>{x.C20}</td>
                      <td>{x.C21}</td>
                      <td>{x.C22}</td>
                      <td>{x.C23}</td>
                      <td>{x.C24}</td>
                      <td>{x.C25}</td>
                      <td>{x.C26}</td>
                      <td>{x.C27}</td>
                      <td>{x.C28}</td>
                      <td>{x.C29}</td>
                      <td>{x.C30}</td>
                      <td>{x.C31}</td>
                      <td>{x.C32}</td>
                      <td>{x.TOTAL}</td>
                    </tr>
                  );
                })}
            </table>

            <img src={CategoryImage} alt="categoryimage" />
          </Box>
        )}
      </Box>
    </Container>
  );
};
