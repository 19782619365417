import React, { useState, useEffect, useContext } from "react";
import {
  Fab,
  List,
  ListItem,
  Divider,
  Paper,
  Button,
  Pagination,
  Container,
  Grid,
  TextField,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NotificationContainer } from "react-notifications";
import { Search } from "@mui/icons-material";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import "react-notifications/lib/notifications.css";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { format } from "date-fns";
import {
  apidata,
  resmode_data,
  category_data,
  district_data,
} from "../../data/data";
import { Store } from "../../utils/Store";
import Itemdetailsrequest from "../details/Itemdetailsrequest";

function InquaryList() {
  const date = new Date();
  const { t } = useTranslation();
  const { state } = useContext(Store);
  const { id, token, user_district, user_roles, location } = state.userInfo;
  const [currentrole, setCurrentrole] = useState(user_roles[0]);
  const [complains, setComplains] = useState([]);
  const [loadingcomplains, setLoadingcomplains] = useState(false);
  const [page, setPage] = useState(0);
  const [totalpages, setTotalpages] = useState(0);
  const [count, setCount] = useState(0);
  const [center, setCenter] = useState(location);
  const [size, setSize] = useState(50);
  const [newcount, setNewcount] = useState(0);
  const [opencount, setOpencount] = useState(0);
  const [closecount, setClosecount] = useState(0);
  const [issearch, setIssearch] = useState(false);
  const [searchkey, setSearchkey] = useState("");
  const [liststate, setListstate] = useState(true);
  const [itemdetails, setItemdetails] = useState(null);
  const [child, setChild] = useState({
    search_key: "",
  });
  const [itemstatus, setItemstatus] = useState("NEW");

  const fetchComplains = (page, size, center, searchkey, status) => {
    setLoadingcomplains(true);
    axios
      .get(apidata.api + "edritem", {
        params: {
          init_res_center: center,
          item_type: "REQUEST",
          page: page,
          size: size,
          searchkey: searchkey,
          status: status,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status == 200) {
          setLoadingcomplains(false);
          setComplains(response.data.rows);
          setPage(response.data.currentPage);
          console.log(response.data.currentPage);
          setCount(response.data.count);
          setTotalpages(response.data.totalPages);
        }
      })
      .catch((err) => {
        setLoadingcomplains(false);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchComplains(page, size, center, searchkey, itemstatus);
  }, []);

  const handlePageChange = (event, value) => {
    const val = value - 1;
    if (issearch) {
      fetchComplains(val, size, center, searchkey, itemstatus);
    } else {
      fetchComplains(val, size, center, searchkey, itemstatus);
      setPage(val);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setIssearch(true);
    setChild((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const searchSchema = yup.object({
    search_key: yup.string("").required(t("search_key")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(searchSchema),
  });

  const onSubmit = (data) => {
    setPage(0);
    setTotalpages(0);
    setComplains([]);
    setSearchkey(data.search_key);
    fetchComplains(page, size, center, data.search_key);
  };

  const allClick = () => {
    setIssearch(false);
    setChild({
      search_key: "",
    });
    fetchComplains(0, size, center);
  };

  const itemClick = (item) => {
    setListstate(false);
    setItemdetails(item);
  };

  const getViaStatus = (val) => {
    setItemstatus(val);
    fetchComplains(0, size, center, "", val, user_district);
  };

  const changeFormstate = () => {
    setListstate(!liststate);
  };

  return (
    <Container component="main" minWidth="xs">
      <NotificationContainer />
      {liststate && (
        <div>
          <NotificationContainer />
          <Paper
            elevation={5}
            style={{
              paddingTop: "10px",
              paddingLeft: "10px",
              paddingBottom: "10px",
              background: "#f3e5f5",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container style={{ borderColor: "#dc73ff", padding: 5 }}>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    label={t("search_key")}
                    id="search_key"
                    name="search_key"
                    {...register("search_key", { required: true })}
                    inputProps={{ style: { fontSize: 14 } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
                    onChange={handleInputChange}
                    value={child.search_key}
                  />
                </Grid>
                {errors.search_key && <p>{errors.search_key?.message}</p>}
                <Grid item sm={2}>
                  <Button
                    style={{ marginLeft: 20, marginBottom: 20 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    <Search style={{ marginRight: 10 }} />
                    {t("search")}
                  </Button>
                </Grid>
                {issearch && (
                  <Grid item sm={2}>
                    <Button
                      style={{ marginLeft: 20, marginBottom: 20 }}
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={allClick}
                    >
                      {t("get all")}
                    </Button>
                  </Grid>
                )}
              </Grid>

              {currentrole.id == 4 ||
              currentrole.id == 6 ||
              currentrole.id == 7 ||
              currentrole.id == 8 ? (
                <Grid item sm={12}>
                  <Fab
                    stylecolor="primary"
                    variant="extended"
                    size="small"
                    onClick={() => getViaStatus("NEW")}
                    style={{
                      minWidth: "100px",
                      fontSize: 10,
                      variant: "bold",
                      color: "#fff",
                      margin: 5,
                      background: "#cc00cc",
                    }}
                  >
                    {newcount ? newcount : ""} New
                  </Fab>
                  <Fab
                    color="primary"
                    variant="extended"
                    size="small"
                    onClick={() => getViaStatus("OPEN")}
                    style={{
                      minWidth: "100px",
                      fontSize: 10,
                      variant: "bold",
                      color: "#fff",
                      margin: 5,
                      background: "#990099",
                    }}
                  >
                    {opencount ? opencount : ""} Open
                  </Fab>
                  <Fab
                    color="primary"
                    variant="extended"
                    size="small"
                    onClick={() => getViaStatus("CLOSE")}
                    style={{
                      minWidth: "100px",
                      fontSize: 10,
                      variant: "bold",
                      color: "#fff",
                      margin: 5,
                      background: "#cc99ff",
                    }}
                  >
                    {closecount ? closecount : ""} Closed
                  </Fab>
                </Grid>
              ) : (
                ""
              )}
            </form>
          </Paper>
          <Paper elevation={5} style={{ padding: "30px" }}>
            <Grid container>
              <Grid item sm={12}>
                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                  {!complains.length && !loadingcomplains && (
                    <p>No record found</p>
                  )}
                  {loadingcomplains && <p>Loading ...</p>}
                  {!loadingcomplains &&
                    complains.map((c) => {
                      return (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            onClick={() => {
                              itemClick(c);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                sx={{
                                  backgroundColor: "#ff6f00",
                                }}
                              >
                                <EmojiPeopleIcon />
                              </Avatar>
                            </ListItemAvatar>

                            <ListItemText
                              primary={c.title}
                              secondary={
                                (c.item_ref !== null ? c.item_ref : "") +
                                " Reference: EDRLAE2025" +
                                c.id +
                                " " +
                                format(
                                  new Date(c.createdAt),
                                  "MM/dd/yyyy HH:mm:ss"
                                )
                              }
                            />
                          </ListItem>
                          <Divider />
                        </>
                      );
                    })}
                </List>
              </Grid>
            </Grid>
            <Grid item sm={2}></Grid>
            <Grid item sm={8} style={{ marginTop: 10 }}>
              <Pagination
                style={{ marginTop: 30 }}
                count={totalpages}
                color="secondary"
                page={page + 1}
                onChange={handlePageChange}
              />
            </Grid>
            <Grid item sm={2}></Grid>
          </Paper>
        </div>
      )}
      {!liststate && (
        <Itemdetailsrequest item={itemdetails} changeState={changeFormstate} />
      )}
    </Container>
  );
}

export default InquaryList;
