import React from "react";
import { Container, Grid } from "@mui/material";
import ResmodeCountChart from "./containers/ResmodeCountChart";
import { NotificationContainer } from "react-notifications";


export const ResmodeChart = () => {
  return (
    <Grid
      // component="main"
      style={{ background: "#efefef", minHeight: "100vh" }}
    >
      <NotificationContainer />
      <Grid container>
        <Grid item sm={12}>
          <ResmodeCountChart />
        </Grid>
      </Grid>
    </Grid>
  );
};
