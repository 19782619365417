import React from "react";
import { Grid } from "@mui/material";

import { NotificationContainer } from "react-notifications";
import DistrictCountChartForDistrict from "./containers/DistrictCountChartForDistrict";

export const DashboardChartDistrict = () => {
  return (
    <Grid
      style={{ background: "#efefef", minHeight: "50vh", marginBottom: -100 }}
    >
      <NotificationContainer />
      <Grid container>
        <Grid item sm={12}>
          <DistrictCountChartForDistrict />
        </Grid>
      </Grid>
    </Grid>
  );
};