import React from "react";
import { Container, Grid } from "@mui/material";

//Add piechart for closed complain view 
import ClosedCountChart from "./containers/ClosedCountChart";
import {
  NotificationContainer,
} from "react-notifications";


export const DashboardPieChart = () => {

  return (
    <Container
      component="main"
      style={{ background: "#efefef", minHeight: "40vh" }}
    >
      <NotificationContainer />
      <Grid container>
        <Grid item sm={12}>
          <ClosedCountChart />
        </Grid>
      </Grid>
    </Container>
  );
};